import React from 'react';
import { Container, Segment, Grid, List, Header } from 'semantic-ui-react';
import { Link } from 'gatsby';

const SiteFooter = () => (
  <Segment inverted vertical style={{ padding: '5em 0em' }}>
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={9}>
            <Header inverted as="h4" content="Get in Touch" />
            <List link inverted>
              <List.Item as={Link} to={'contact'}>
                Contact Us
              </List.Item>
              <List.Item as={Link} to={'directions'}>
                Directions
              </List.Item>
            </List>
          </Grid.Column>
          {/*
          <Grid.Column width={3}>
            <Header inverted as="h4" content="Services" />
            <List link inverted>
              <List.Item as={Link} to="/calendar">
                Schedule
              </List.Item>
              <List.Item as="a">Pricing</List.Item>
              <List.Item as="a">Amenities</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as="h4" inverted>
              Info
            </Header>
            <List link inverted>
              <List.Item as="a">What to bring</List.Item>
              <List.Item as={Link} to="/products">
                Buy Now
              </List.Item>
            </List>
          </Grid.Column>
          */}
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
);

export default SiteFooter;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Label, Icon, Responsive, Sidebar, Menu } from 'semantic-ui-react';

import { CartContext } from './CartProvider';

const CartItemsLabel = ({ count, color = 'red' }) => (
  <Label color={color} floating>
    {count}
  </Label>
);

const Cart = () => {
  const { count, toggle } = useContext(CartContext);

  return (
    <Menu.Item onClick={toggle}>
      {/* Desktop, Tablet */}
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Icon name="cart" size="large" />
        {count > 0 && <CartItemsLabel count={count} />}
      </Responsive>
      {/* Mobile */}
      <Responsive
        as={Sidebar.Pushable}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Label>
          <Icon name="cart" />
          {count}
          <Label.Detail>View Cart</Label.Detail>
        </Label>
      </Responsive>
    </Menu.Item>
  );
};

CartItemsLabel.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number.isRequired,
};

Cart.propTypes = {
  // style: PropTypes.object,
};

export default Cart;

import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'gatsby';
import Cart from './Cart';

const PrimaryNav = () => (
  <>
    <Menu.Item as={Link} activeClassName="active" to="/">
      {/* <Image src={logo} /> */}
      Home
    </Menu.Item>
    <Menu.Item as={Link} activeClassName="active" to="/about">
      About Us
    </Menu.Item>
    <Menu.Item as={Link} activeClassName="active" to="/directions">
      Directions
    </Menu.Item>
    <Menu.Item position="right">
      {/*
          <Button as="a" inverted={!fixed}>
            Log in
          </Button>
          <Button
            as="a"
            inverted={!fixed}
            primary={fixed}
            style={{ marginLeft: "0.5em" }}
          >
            Sign Up
          </Button>
          */}
    </Menu.Item>
    <Cart style={{ marginLeft: '0.5em' }} />
  </>
);

export default PrimaryNav;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Item, Menu } from 'semantic-ui-react';

import picture from '../images/vincent-van-zalinge-388025-unsplash.jpg';
import { CartContext } from './CartProvider';

const CartItem = ({ sku, quantity }) => {
  const { remove, toggle } = useContext(CartContext);
  return (
    <Menu.Item key={sku.id} style={{ display: 'flex', margin: '1rem 0' }}>
      {/* <img src={sku.image || sku.product.images[0]} alt={sku.product.name} /> */}

      <Item.Group divided>
        <Item>
          <Item.Image size="tiny" src={picture} />
          <Item.Header style={{ margin: '0.5em auto' }}>
            {sku.product.name}
          </Item.Header>
          <Item.Content verticalAlign="middle">
            <p>
              <strong>${(sku.price / 100) * quantity}</strong>
            </p>
            <Item.Extra>
              <Button
                animated="vertical"
                secondary
                onClick={() => {
                  remove(sku.id);
                  toggle(false);
                }}
              >
                <Button.Content hidden>Remove</Button.Content>
                <Button.Content visible>
                  <Icon name="trash alternate outline" />
                </Button.Content>
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </Menu.Item>
  );
};

CartItem.propTypes = {
  sku: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default CartItem;

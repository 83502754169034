import React from 'react';
import { Button, Container, Header, Icon } from 'semantic-ui-react';

import { StaticQuery, graphql } from 'gatsby';

import CallTodayModal from './CallTodayModal';

const HomePageHeader = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Container text inverted style={{ margin: '5em' }}>
        <Header as="h1" content={data.site.siteMetadata.title} inverted />
        <Header
          as="h2"
          content="The best mix of hospitality and hunting"
          inverted
        />
        <CallTodayModal
          trigger={
            <Button primary size="large">
              Get Started
              <Icon name="right arrow" />
            </Button>
          }
        />
      </Container>
    )}
  />
);

export default HomePageHeader;

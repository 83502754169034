// import React, { useContext } from 'react';
import React from 'react';
import { Container, Header, Segment, Icon, List } from 'semantic-ui-react';

// import { CartContext } from '../components/CartProvider';
import Layout from '../components/Layout';

const Order = ({ location }) => {
  // const { order } = useContext(CartContext);
  const order = location.search.split('=')[1];

  return (
    <Layout slimHeader location={location}>
      <Segment placeholder>
        <Header icon>
          <Icon name="sun" />
          Thank you for your order!
        </Header>
      </Segment>

      <Container text>
        <p>
          We have received your order, and you should receive an email from
          Stripe, shortly.
        </p>
        <p>
          Your order number is <strong>{order}</strong> Please record this for
          future reference. (You should also receive this in email).
        </p>
        <p>
          {/* TODO: make this dynamic */}
          Please call us at ‪(706) 963-0786‬ if you have any questions about
          your order, including cancellation.
        </p>
      </Container>

      <Container text style={{ margin: '20px 0' }}>
        Things to prepare for your trip
        <List bulleted>
          <List.Item>ammo</List.Item>
        </List>
      </Container>
    </Layout>
  );
};

export default Order;

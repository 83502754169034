import React from 'react';
import PropTypes from 'prop-types';
import { Container, Segment, Menu } from 'semantic-ui-react';

import PrimaryNav from './PrimaryNav';
import HomePageHeader from './HomePageHeader';

const SiteHeader = ({ slim = true }) => (
  <Segment inverted textAlign="center" vertical>
    <Menu inverted stackable secondary size="large">
      <Container>
        <PrimaryNav />
      </Container>
    </Menu>
    {slim ? '' : <HomePageHeader />}
  </Segment>
);

SiteHeader.propTypes = {
  siteTitle: PropTypes.string,
};

export default SiteHeader;

import React from 'react';
import { Button, Container, Modal } from 'semantic-ui-react';

import PhoneNumber from './PhoneNumber';

const defaultTrigger = <Button primary>Call us Today to Schedule</Button>;

const CallTodayModal = ({ trigger = defaultTrigger }) => (
  <Modal
    trigger={trigger}
    header="Schedule today!"
    content={
      <Container text>
        Call us at <PhoneNumber /> to schedule your hunt.
      </Container>
    }
    actions={[{ key: 'done', content: 'Done', positive: true }]}
  />
);

export default CallTodayModal;

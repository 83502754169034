import React, { useContext } from 'react';
import { Menu, Sidebar, Button } from 'semantic-ui-react';
import { Link } from 'gatsby';

import { CartContext } from './CartProvider';
import CartItem from './CartItem';
import PhoneNumber from './PhoneNumber';

const CartSideBar = () => {
  const { cart, count, mode, toggle } = useContext(CartContext);

  // console.log('mode is ', mode);

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      onHide={() => toggle(false)}
      vertical
      visible={!!mode}
      width="thin"
    >
      <div style={{ color: '#fff', padding: '1em' }}>
        <h1>Cart</h1>
        {count === 0 && (
          <p>
            Cart is not currently operative. Please call <PhoneNumber /> to
            schedule.
          </p>
        )}
        {cart.map(([sku, quantity]) => (
          <CartItem key={sku.id} sku={sku} quantity={quantity} />
        ))}
        {count !== 0 && (
          <Button primary as={Link} to="/calendar">
            Schedule
          </Button>
        )}
      </div>
    </Sidebar>
  );
};

export default CartSideBar;

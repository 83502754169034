import React from 'react';
import PropTypes from 'prop-types';
import { Responsive, Visibility, Sidebar, Segment } from 'semantic-ui-react';

import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import ProductsProvider from './ProductsProvider';
import CartProvider from './CartProvider';
import CartSideBar from './CartSideBar';

const Layout = ({ children, slimHeader = true }) => (
  <ProductsProvider>
    <CartProvider>
      <Responsive>
        <Visibility once={false}>
          <Sidebar.Pushable as={Segment}>
            <CartSideBar />
            <Sidebar.Pusher>
              <SiteHeader slim={slimHeader} />
              <main>{children}</main>
              <SiteFooter />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Visibility>
      </Responsive>
    </CartProvider>
  </ProductsProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
